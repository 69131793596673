.footer{
    background-color: #312E2E;
    width: 100%;
    padding: 4em 0;
}

.footer .footer-wrapper{
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    gap: 2em;
}

.footer .footer-wrapper .footer-left{
    display: flex;
    justify-content: space-around;
    gap: 3em;
    width: 40%;
}

.footer .footer-wrapper .footer-right{
    display: flex;
    gap: 3em;
    justify-content: space-between;
    width: 55%;
}

.footer .footer-wrapper .social{
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.footer .footer-wrapper .social .email{
    color: #FDF7F7;
    font-size: 13px;
    color: #D0CDCD;
}

.footer .footer-wrapper .social img{
    width: 115px;
}

.numbers{
    display: flex;
    flex-direction: column;
    gap: 1.5em;
}

.numbers h4{
    color: #FFFFFF;
}

.numbers p{
    color: #D0CDCD;
    font-size: 13px;
    text-decoration-line: underline;
}

.footer .footer-wrapper .office{
    display: flex;
    flex-direction: column;
    gap: 2em;
}

.footer .footer-wrapper .office h4{
    color: #FFFFFF;
    margin-bottom: .5em;
}

.footer .footer-wrapper .office p{
    color: #D0CDCD;
    max-width: 300px;
    font-size: 13px;
}

.footer .footer-wrapper .footer-links h4{
    color: #FFFFFF;
}

.footer .footer-wrapper .footer-links .main-footer-links{
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.footer .footer-wrapper .footer-links .resource{
    display: flex;
    gap: 1em;
    flex-direction: column;
}

.footer .footer-wrapper .footer-links .resource .resource-main{
    display: flex;
    gap: 1em;
    color: #D0CDCD;
}

.footer .footer-wrapper .footer-links .resource .resource-main p{
    color: #D0CDCD;
    font-size: 13px;
}

.footer .footer-wrapper .footer-links{
    display: flex;
    flex-direction: column;
    gap: 2em;
}

.footer .footer-wrapper .footer-links .main-footer-links .navbar-nav{
    display: flex;
    gap: 1em;
}

.footer .footer-wrapper .footer-links .main-footer-links .navbar-nav a{
    color: #D0CDCD;
    text-decoration: none;
    font-size: 13px;
}


.footer .footer-wrapper .icons{
    display: flex;
    gap: 2em;
}

.footer .footer-wrapper .icons a{
    color: #D0CDCD;
    font-size: 1.2rem;
}

.footer .copyright{
    display: flex;
    justify-content: center;
    margin-top: 3em;
    color: #8B8282;
    gap: 2em;
}

.footer .copyright .dot{
    margin-left: 10px;
}

.footer .copyright .copy-privacy{
    display: flex;
    gap: 1em;
}

.footer .copyright .cookie-terms{
    display: flex;
}

@media screen and (max-width: 922px){
    .footer .footer-wrapper{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 5em;
    }

    .footer .footer-wrapper .footer-left{
        justify-content: space-between;
        width: 80%;
    }

    .footer .footer-wrapper .footer-right{
        justify-content: space-between;
        width: 80%;
        justify-items: flex-end;
        gap: 5em;
    }

    .footer .footer-wrapper .office{
        min-width: 100px;
    }
}

@media screen and (max-width: 702px){
    .footer .footer-wrapper .footer-left{
        flex-direction: column;
    }

    .footer .footer-wrapper .footer-right{
        flex-direction: column;
    }
}

@media screen and (max-width: 372px){
    .footer .footer-wrapper .footer-links .main-footer-links .navbar-nav{
        flex-direction: column;
    }

    .footer .footer-wrapper .footer-links .resource .resource-main{
        flex-direction: column;
    }

    .footer-dot{
        display: none;
    }
}