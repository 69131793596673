:root{
    --swiper-theme-color: #EC1F2F !important;
}

.testimonial{
    width: 90%;
    margin: 0 auto;
    z-index: 1;
    position: relative;
}

.testimonial .testimonial-wrapper{
    z-index: 1;
}

.review-red{
    color: var(--mainRed);
}

.review-heading-text{
    clip-path: polygon(0 100%, 99% 100%, 100% 100%, 0% 100%);
    opacity: 0;
    transform: translateY(100px)
}

.review-heading-text p{
    max-width: 500px;
    opacity: 0.7;
}

.review-portfolio-btn{
    opacity: 0;
    transform: translateY(50px);
}

/* .reviews{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
    gap: 3em;
    width: 100%;
    margin-bottom: 6em;
    margin-left: auto;
} */

.reviews{
    padding: 1em 1em !important;
    position: relative;
    width: 100%;
}

#swiper-slide-outer{
    background-color: hsl(0, 2%, 23%) !important;
}

.swiper{
    z-index: 1 !important;
    position: relative !important;
    padding: 1em 0em !important;
    width: 100% !important;
    height: 100%;
    max-width: 1920px !important;
}

.swiper-wrapper{
    z-index: -1 !important;
    height: unset !important;
    display: flex;
    text-align: center;
    width: 100%;
    max-width: 100% !important;
    z-index: 1;
    transform: unset;
}

.reviews .review-main{
    margin: 0 auto;
    border-radius: 14px;
    padding: 2em 0em 2em 0em;
    margin-bottom: 2em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    box-shadow: -1px -1px 15px 1px rgba(0, 0, 0, 0.08);
    position: relative;
    z-index: 1000;
    height: auto;
}

/* @media screen and (min-width: 574px) {
    .reviews .review-main {
      width: 50% !important;
      
    }
}

@media screen and (min-width: 850px) {
    .reviews .review-main {
      width: 30% !important;
      
    }
} */

/* @media screen and (min-width: 808px) {
    .reviews .review-main {
      width: 50% !important;
    }
}

@media screen and (min-width: 900px) {
    .reviews .review-main {
      width: 33.5% !important;
    }
} */

.reviews .review-main .review-img{
    position: relative;
    width: max-content;
}

.reviews .review-main .review-img img{
    width: 100px;
    height: 100px;
    border-radius: 100%;
}

.reviews .review-main .review-img .review-text{
    width: 100%;
    padding: 1.5em 0;
    text-align: center;
}

.reviews .review-main .review-img .review-text .name{
    color: #3C3939;
    font-weight: medium;
    font-size: 15px;
    margin-bottom: .5em;
}

.reviews .review-main .review-img .review-text .role{
    font-size: 14px;
    font-weight: 500;
    color: #3B3939;
    margin-bottom: .5em;
}

.swiper-nav-btns{
    display: flex;
    gap: 1.5em;
    justify-content: center;
    margin-bottom: 1em !important;
}

.swiper-nav-btns .testimonialLeft{
    cursor: pointer;
    top: 40%;
    left: -30px;
    z-index: 1;
    transform: translateX(-100px);
    width: 30px;
}

.swiper-nav-btns .testimonialRight{
    cursor: pointer;
    top: 40%;
    right: -30px;
    z-index: 1;
    transform: translateX(100px);
    width: 30px;
}

/* .swiper-nav-btns{
    z-index: -1000;
    background-color: #3C3939;
    padding: 4em;
} */

.reviews .review-main .review-img .review-text .company{
    color: rgba(248, 98, 98, 1);
    font-size: 12px;
}

.reviews .review-main .review-text-main{
    text-align: center;
}

.reviews .review-main .review-text-main p{
    font-size: 12px;
    opacity: 60%;
    padding: 0 1em;
}

@media screen and (max-width: 670px){
    .reviews{
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }
}