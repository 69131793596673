:root {
  --gray: #8B8282;
  --mainRed: #EC1F2F;
}

html *, html *::before, html *::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar{
  height: 12px;
  width: 12px;
}
  
::-webkit-scrollbar-track{
  background: rgba(255, 255, 255, 0.2);
  border-radius: 1px;
}

::-webkit-scrollbar-thumb{
  background: #EC1F2F;
  border-radius: 1px;
}

@font-face {
  font-family: 'inter';
  src: url(fonts/Inter-Medium.otf) format('opentype');
  font-weight: medium;
}

@font-face {
  font-family: 'inter';
  src: url(fonts/Inter-Regular.otf) format('opentype');
  font-weight: normal;
}

@font-face {
  font-family: 'inter';
  src: url(fonts/Inter-SemiBold.otf) format('opentype');
  font-weight: bold;
}

.App{
  margin: 0 auto;
  background-color: white;
  overflow: hidden;
  padding-top: 70px;
  font-family: 'inter';
  scroll-behavior: smooth;
  position: relative;
  z-index: 100;
}

input,
textarea,
button,
select,
a{
  -webkit-tap-highlight-color: transparent;
}



/* body{
  position: fixed;
} */


.hero-bottom{
  width: 95%;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.hero-bottom .portfolio-btn p{
  opacity: 0.8;
}

.hero-bottom .portfolio-btn{
  display: flex;
  align-items: center;
  align-self: flex-end;
  gap: 1em;
  cursor: pointer;
}



.main-red{
  color: var(--mainRed);
}

*{
  scroll-behavior: none;
}

@media screen and (max-width: 702px){
  .hero-bottom{
    flex-direction: column;
    text-align: center;
    gap: 2em;
  }
  .hero-bottom .portfolio-btn{
    align-self: center;
  }
}