.it-hero{
    position: relative;
}

.it-hero .it-hero-wrapper{
    width: 90%;
    margin: 0 auto;
}

.it-hero .it-hero-wrapper .hero-top{
    display: flex;
    justify-content: space-between;
    width: 90%;
    align-items: center;
    gap: 2em;
    min-height: 84vh;
}

.it-hero .it-hero-wrapper .hero-top img{
    width: clamp(500px, 30vw, 700px);
    opacity: 0;
    transform: scale(.5);
}

.it-hero .it-hero-wrapper .hero-top .hero-text{
    display: flex;
    flex-direction: column;
    gap: 1.5em;
}

.it-hero .it-hero-wrapper .hero-top .it-hero-text{
    clip-path: polygon(0 100%, 99% 100%, 100% 100%, 0% 100%);
    opacity: 0;
    transform: translateY(100px);
}

.it-hero .it-hero-wrapper .hero-top .hero-text h1{
    font-size: clamp(3rem, 4vw, 5rem);
}

.it-hero .it-hero-wrapper .hero-top .hero-text>:nth-child(2){
    font-size: 20px;
    max-width: 400px;
}

.it-hero .it-hero-wrapper .hero-top .hero-text>:nth-child(3){
    max-width: 570px;
    opacity: 70%;
    font-size: 15px;
}

.it-hero .it-hero-wrapper .hero-bottom{
    margin-bottom: 70px;
    padding-top: 100px;
}

.it-hero .it-hero-wrapper .hero-bottom .hero-text p{
    max-width: 590px;
    opacity: 60%;
    font-size: 15px;
}

.it-hero .it-hero-wrapper .hero-bottom .it-portfolio-text{
    clip-path: polygon(0 100%, 99% 100%, 100% 100%, 0% 100%);
    opacity: 0;
    transform: translateY(100px);
}

.it-hero .it-hero-wrapper .hero-bottom .it-portfolio-btn{
    opacity: 0;
    transform: translateY(50px);
}

.it-hero .it-hero-wrapper .hero-bottom .hero-text h1{
    margin-bottom: .5em;
    font-size: clamp(2.2rem, 3.3vw, 4rem);
}

.it-doing-display{
    display: grid;
    gap: 1em;
    grid-row-gap: 4em;
    align-items: center;
    grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
    margin-bottom: 130px;
}

.it-doings-text{
    opacity: 0;
    transform: translateY(100px);
    clip-path: polygon(0 100%, 99% 100%, 100% 100%, 0% 100%);
}

.it-doings-img{
    opacity: 0;
    transform: translateY(100px);
}


@media screen and (min-width: 1723px){
    .it-doing-display{
        grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
    }
}

@media screen and (max-width: 950px){
    .it-hero .it-hero-wrapper .hero-top{
        width: 100%;
    }
}

@media screen and (max-width: 854px){
    .it-hero .it-hero-wrapper .hero-top{
        flex-direction: column-reverse;
        text-align: center;
    }

    .it-hero .it-hero-wrapper .hero-top img{
        width: 90% !important;
    }

    .it-hero .it-hero-wrapper .hero-top .hero-text{
        gap: 1em;
    }

    .it-hero .it-hero-wrapper .hero-top .hero-text>:nth-child(2),
    .it-hero .it-hero-wrapper .hero-top .hero-text>:nth-child(3){
        max-width: 100%;
    }

    .it-hero{
        margin-top: 40px;
    }
   
}

@media screen and (max-width: 514px){
    .it-hero .it-hero-wrapper .hero-top .hero-text h1{
        font-size: 2.5rem;
    }

    .it-hero .it-hero-wrapper .hero-top img{
        width: 90%;
    }
}

@media screen and (max-width: 912px){
    .hero-bottom{
      flex-direction: column;
      text-align: center;
      gap: 2em;
    }
    .hero-bottom .portfolio-btn{
      align-self: center !important;
    }

    .ready-custom > :nth-child(3){
        max-width: 100%;
    }
}
  
@media screen and (max-width: 970px){
    .it-doing-display{
        align-items: flex-start;
    }
}

@media screen and (max-width: 576px){
    .it-doing-display{
        display: block;
    }
}

