.doings{
    margin-bottom: 2em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 3em;
    width: 100%;
    margin: 0 auto;
    height: 100%;
}

.doings-wrapper{
    background: rgba(175, 171, 171, 25%);
    width: 100%;
    height: 100%;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 1em;
    gap: 1em;
    align-items: center;
    text-align: center;
    justify-content: center;
}



.doings-wrapper h4{
    font-weight: medium;
}

.doings-wrapper>:nth-child(3){
    font-size: 13px;
    opacity: 50%;
    max-width: 300px;
    line-height: 1.7;
}

.doings-wrapper .see-more a{
    color: var(--mainRed);
    text-decoration: underline;
    cursor: pointer;
}

.doings-main{
    display: flex;
    align-items: center;
    gap: 2em;
    margin-bottom: 80px;
    height: 300px;
    
}

@media screen and (max-width: 701px){
    .doings-main{
        gap: 0;
    }
    .doings-wrapper>:nth-child(3){
        max-width: 100%;
    }
}

@media screen and (max-width: 382px){
    .doings{
        width: 100%;
    }
}

@media screen and (max-width: 1127px){
    .doings-main{
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 428px){
    .doings-main{
        width: 100%;
    }
}