.web-hero{
    position: relative;
}

.webstar{
    position: absolute;
    left: -300px;
    top: -130px;
    width: 750px;
}

.webcircle{
    position: absolute;
    position: absolute;
    top: 1% !important;
    right: 30px;
}

.web-hero .web-hero-wrapper{
    width: 90%;
    margin: 0 auto;
}

.web-hero .web-hero-wrapper .hero-top{
    display: flex;
    justify-content: space-between;
    width: 90%;
    align-items: center;
    min-height: 84vh;
}

.web-hero .web-hero-wrapper .hero-top .web-hero-img{
    opacity: 0;
    transform: scale(.5);
}

.web-hero .web-hero-wrapper .hero-top .hero-text{
    display: flex;
    flex-direction: column;
    gap: 3em;
    clip-path: polygon(0 100%, 99% 100%, 100% 100%, 0% 100%);
    opacity: 0;
    transform: translateY(100px);
}

.web-hero .web-hero-wrapper .hero-top .hero-text h1{
    font-size: clamp(3rem, 5vw, 7rem);
}

.web-hero .web-hero-wrapper .hero-top .hero-text>:nth-child(3){
    width: max(400px, 70%);
    opacity: 70%;
    font-size: clamp(15px, 1vw, 30px);
}

.web-hero .web-hero-wrapper .hero-top img{
    z-index: 2;
}

.web-hero .web-hero-wrapper .hero-bottom .web-bottom-text p{
    max-width: 550px;
    opacity: 60%;
    font-size: 15px;
}

.web-hero .web-hero-wrapper .hero-bottom .web-bottom-text h1{
    margin-bottom: .5em;
    font-size: clamp(2.2rem, 3vw, 5rem);
}

.web-hero .web-hero-wrapper .hero-bottom .web-bottom-text{
    width: fit-content;
}



.web-hero .web-hero-wrapper .hero-top .hero-text .web-p-bold{
    font-size: 19px;
}

.web-hero .web-hero-wrapper .hero-bottom{
    margin-bottom: 70px;
}

.web-hero .web-hero-wrapper .hero-bottom .web-bottom-text{
    clip-path: polygon(0 100%, 99% 100%, 100% 100%, 0% 100%);
    opacity: 0;
    transform: translateY(100px);
}

.portfolio-btn-web1{
    opacity: 0;
    transform: translateY(50px)
}

.web-doing-display{
    display: grid;
    gap: 8em;
    grid-row-gap: 4em;
    grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
    margin-bottom: 150px;
}

/* .web-doing-display>:nth-child(2){
    align-self: flex-end;
    align-items: flex-end;
    justify-self: end;
} */

.web-doings-img{
    opacity: 0;
    transform: translateY(100px);
}

.web-doings-text{
    opacity: 0;
    transform: translateY(100px);
    clip-path: polygon(0 100%, 99% 100%, 100% 100%, 0% 100%);
}

.Expertise h1{
    margin-bottom: 100px;
    font-size: clamp(2rem, 2.5vw, 5rem);
}

.Expertise-main{
    width: 95%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
    grid-gap: 2em;
    margin-left: 50px;
    
}

.Expertise-main .doings{
    width: 100%;
}

.expert-same{
    transform: translateY(100px);
    opacity: 0;
}

.Expertise-main .doings .doings-wrapper{
    width: 100%;
}

/* This will be for a certain viewport width */
/* .Expertise-main>:nth-child(3){
    grid-column-start: 1;
    grid-column-end: 3;
} */

.Expertise-main>:nth-child(3)>.arrow{
    visibility: hidden;
}

.ready-custom-main{
    padding: 10em 0;
}

.ready-custom>:nth-child(1){
    margin-bottom: .2em;
    font-size: clamp(2.2rem, 3vw, 3rem);
}

.ready-custom>:nth-child(2){
    margin-bottom: .2em;
    font-size: clamp(2.2rem, 2.5vw, 3rem);
}

.ready-custom>:nth-child(3){
    max-width: 700px;
    opacity: 60%;
}

.ready-custom-portfolio-btn{
    opacity: 0;
    transform: translateY(50px);
}

.getting{
    margin-bottom: 150px;
}

.getting .getting-text-h1{
    margin-bottom: 70px;
}

.getting .getting-text-h1 h1{
    margin-bottom: .5em;
    font-size: clamp(2.2rem, 3vw, 3rem);
}

.getting .getting-text p{
    opacity: 61%;
    font-size: 15px;
}



.getting .getting-main{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3em;
    flex-wrap: wrap;
}

.getting .getting-main .getting-same .getting-text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: .8em;
}



.getting .getting-text .getting-text-main{
    display: flex;
    flex-direction: column;
    gap: .8em;
    opacity: 0;
    transform: translateY(50px);
}

.getting .getting-text .getting-img{
    opacity: 0;
    transform: translateY(50px);
}

.getting .getting-main>:nth-child(1)>.getting-text{
    width: fit-content;
}

.getting .getting-main>:nth-child(2)>.getting-text{
    width: fit-content;
    margin-left: auto;
}

.getting .getting-main>:nth-child(1){
    flex-basis: 45%;
}
.getting .getting-main>:nth-child(2){
    flex-basis: 45%;
}

.getting .getting-main .getting-same .getting-text img{
    width: 90px;
}

.getting .getting-main .getting-same .getting-text p{
    max-width: 400px;
}

@media screen and (max-width: 594px){
    .getting .getting-text-h1{
        text-align: center;
    }
}

@media screen and (min-width: 1723px){
    .web-doing-display{
        grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
        grid-row-gap: 6em;
    }

    .web-doings .web-doings-wrapper .web-doings-item{
        gap: 1em !important;
    }
}

@media screen and (max-width: 1068px){
    .ready-custom > :nth-child(3){
        max-width: 500px;
    }
}

@media screen and (max-width: 880px){
    .web-hero .web-hero-wrapper .hero-top .hero-text>:nth-child(3){
        max-width: 100%;
    }

    .web-hero .web-hero-wrapper .hero-top{
        width: 100%;
    }
}

@media screen and (max-width: 1014px){
    .web-hero .web-hero-wrapper  .hero-top img{
        width: 300px;
    }
}

@media screen and (max-width: 746px){
    .hero-top{
        flex-direction: column-reverse;
        text-align: center;
    }

    

    .web-hero{
        margin-top: 20px;
    }

    .pattern2{
        opacity: 0%;
    }

    .web-hero .web-hero-wrapper .hero-top .hero-text{
        gap: 1em;
    }

    .web-hero .web-hero-wrapper .hero-top .hero-text h1{
        font-size: 3rem;
    }
}

@media screen and (max-width: 428px){
    .web-hero .web-hero-wrapper  .hero-top img{
        width: 90% !important;
    }

    .web-hero .web-hero-wrapper .hero-top .hero-text h1{
        font-size: 2.5rem;
    }
}

@media screen and (max-width: 928px){
    .web-hero .web-hero-wrapper .hero-bottom .web-bottom-text p{
        max-width: 90%;
    }
}

@media screen and (max-width: 702px){
    .web-hero .web-hero-wrapper .hero-bottom .web-bottom-text p{
        max-width: 100%;
    }
}

@media screen and (max-width: 482px){
    .web-doing-display{
        grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
        grid-row-gap: 1em;
    }

    .doings-main{
        width: 100%;
    }
}

@media screen and (max-width: 613px){
    .Expertise h1{
        text-align: center;
        font-size: 1.6rem !important;
        margin-bottom: 50px;
    }
}

@media screen and (max-width: 613px){
    .Expertise h1{
        font-size: 1.5rem !important;
        width: 100%;
    }
}

@media screen and (max-width: 1127px){
    .Expertise-main{
        grid-template-columns: repeat(auto-fill, minmax(50%, 50%));
    }

    .Expertise-main>:nth-child(3){
        grid-column-start: 1;
        grid-column-end: 3;
    }

    .Expertise-main .doings-main{
        margin-bottom: 30px;
    }

    .Expertise-main .arrow{
        display: none;
    }

    .Expertise-main{
        margin-left: 0;
    }
}

@media screen and (max-width: 715px){
    .Expertise-main{
        width: 100%;
        grid-template-columns: unset;
    }
}

@media screen and (max-width: 591px){
    .Expertise-main{
        display: block;
        width: 100%;
    }

    .Expertise-main .doings{
        width: 90%;
    }

    .Expertise-main .doings-main{
        margin-bottom: 70px;
    }
}

@media screen and (max-width: 532px){
    .getting .getting-main > :nth-child(1){
        flex-basis: 100%;
    }

    .getting .getting-main .getting-same .getting-text p{
        max-width: 100%;
    }

    .getting .getting-main > :nth-child(2){
        flex-basis: 100%;
    }
}


@media screen and (max-width: 682px){
    .web-hero .web-hero-wrapper .hero-bottom{
        margin-bottom: 50px;
    }
}

