.contact{
    width: 90%;
    margin: 0 auto;
    margin-bottom: 70px;
    padding-top: 6rem;
}

.contact .contact-wrapper .contact-heading{
    text-align: center;
    margin-bottom: 2em;
}


.contact .contact-wrapper .contact-heading h1{
    font-size: 32px;
    background-color: #EC1F27;
    max-width: 677px;
    padding: .2em 0em;
    color: white;
    margin: 0 auto;
    border-radius: 10px 0px;
    margin-bottom: .7em;
}

.contact .contact-wrapper .contact-heading p{
    font-size: 20px;
    font-weight: 400;
    opacity: 0.9;
}

/* .lets{
    color: var(--mainRed);
} */

.contact .contact-wrapper .contact-main{
    transform: translateY(200px);
    opacity: 0;
}

.contact .contact-wrapper .contact-main #form{
    display: flex;
    flex-direction: column;
    gap: 2em;
    
}

.contact .contact-wrapper .contact-main{
    max-width: 677px;
    margin: 0 auto;
}

.contact .contact-wrapper .contact-main #form .contact-same{
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.contact .contact-wrapper .contact-main #form .contact-same label{
    font-weight: normal;
}

.contact .contact-wrapper .contact-main #form .contact-same input{
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.28);
    background: rgba(220, 214, 214, 0.7);
    border: none;
    border-radius: 15px;
    padding: .8em .8em .8em 1.5em;
    font-family: 'inter';
    outline: none;
    font-size: 1.1rem;
}

.contact .contact-wrapper .contact-main #form .contact-same textarea{
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.28);
    background: rgba(220, 214, 214, 0.7);
    border: none;
    border-radius: 15px;
    padding: .8em .8em .8em 1.5em;
    outline: none;
    resize: none;
    height: 100px;
    font-size: 1.1rem;
    font-family: 'inter';
}

.contact .contact-wrapper .contact-main #form .contact-same input::placeholder{
    color: #000000;
    font-size: 15px;
    font-weight: 600;
    opacity: 50%;
}

.contact .contact-wrapper .contact-main #form .contact-same textarea::placeholder{
    color: #000000;
    font-size: 15px;
    font-weight: 600;
    opacity: 50%;
}

.contact .contact-wrapper .contact-main #form .submit{
    text-align: center;
}

.contact .contact-wrapper .contact-main #form .submit button{
    padding: 1em 2.3em;
    border-radius: 10px 5px;
    background: #EC1F27;
    outline: none;
    border: none;
    cursor: pointer;
    color: white;
}

/* I changed the position for toastify here because it was been covered */
.Toastify__toast-container--top-right{
    top: 4em !important;
}