.it-doings{
    margin-bottom: 4em;
    width: 100%;
}

.it-doings .it-doings-wrapper .it-doings-item{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3em;
}

.it-doings .it-doings-wrapper .it-doings-item .it-doings-text{
    text-align: center;
}

.it-doings .it-doings-wrapper .it-doings-item .it-doings-text h3{
    margin-bottom: 8px;
}

.it-doings .it-doings-wrapper .it-doings-item .it-doings-text p{
    font-size: 13px;
    opacity: 61%;
}

@media screen and (max-width: 1124px){
    .it-doings .it-doings-wrapper .it-doings-item{
        gap: 1em;
    }
}

@media screen and (max-width: 970px){
    .it-doings .it-doings-wrapper .it-doings-item{
        flex-direction: column;
    }
}

@media screen and (max-width: 576px){
    .it-doings .it-doings-wrapper .it-doings-item .it-doings-text p{
        max-width: 100%;
    }

    .it-doings .it-doings-wrapper .it-doings-item img{
        width: 100px;
    }
}

@media screen and (max-width: 576px){
    .it-hero .it-hero-wrapper .hero-bottom{
        margin-bottom: 40px;
    }
}

