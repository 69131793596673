.hero{
    width: 90%;
    margin: 0 auto;
    position: relative;
}

.homestar{
    position: absolute;
    left: -300px;
    top: -200px;
    width: 750px;
}

.homecircle{
    position: absolute;
    top: 3%;
    right: 0px;
}

.hero .hero-wrapper{
    margin-bottom: 70px;
}

.hero .hero-wrapper .hero-top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1em;
    height: 84vh;
    /* background-image: url(/src/assets/PATTERN1.png); */
    margin-bottom: 50px;
    
}

.hero .hero-wrapper .hero-top .hero-text{
    display: flex;
    justify-content: center;
    gap: 2em;
    flex-direction: column;
    justify-content: space-between;
    clip-path: polygon(0 100%, 99% 100%, 100% 100%, 0% 100%);
    opacity: 0;
    transform: translateY(100px);
}

.hero .hero-wrapper .hero-top .hero-text h1{
    font-size: clamp(3rem, 5vw, 5rem);
    margin-bottom: 23px;
    font-weight: bold;
}

.hero .hero-wrapper .hero-top .hero-img{
    width: clamp(500px, 30vw, 700px);
}

.hero .hero-wrapper .hero-top .hero-img{
    opacity: 0;
    transform: scale(.5);
}

.hero .hero-wrapper .hero-top .hero-text h1 .effi{
    color: var(--mainRed);
}

.hero .hero-wrapper .hero-top .hero-text .main-p-hero{
    width: max(400px, 70%);
    font-size: clamp(16px, 1vw, 30px);
    font-weight: normal;
    color: #000000;
    opacity: 0.7;
}

.hero .hero-wrapper .hero-top .hero-text a{
    color: white;
    text-decoration: none;
}

.see-more-hero{
    background-color: #EC1F27;
    outline: none;
    border: none;
    width: 148px;
    height: 41px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1em;
    color: white;
    font-size: 16px;
    border-radius: 8px 0px;
    margin-top: 2em !important;
}

.see-more-hero p{
    color: white;
    z-index: 11111;
    margin-bottom: 0 !important;
}

.see-more-hero img{
    width: 25px !important;
    height: 20px;
}

.hero .hero-wrapper .hero-bottom{
    margin-bottom: 100px;
    padding-top: 70px;
}

.hero .hero-wrapper .hero-bottom .hero-text{
    clip-path: polygon(0 100%, 99% 100%, 100% 100%, 0% 100%);
    opacity: 0;
    transform: translateY(100px);
}

.hero .hero-wrapper .hero-bottom .portfolio-btn-home1{
    opacity: 0;
    transform: translateY(50px)
}

.hero .hero-wrapper .hero-bottom .hero-text h1{
    font-size: 2.5rem;
    margin-bottom: 23px;
    font-weight: bold;
    
}

.hero .hero-wrapper .hero-bottom .hero-text h1 .we{
    color: var(--mainRed);
}

.hero .hero-wrapper .hero-bottom .hero-text p{
    max-width: 400px;
    opacity: 0.7;
}

.doings-display{
    max-width: 2100px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
}

.doings-display .doings .doings-wrapper{
    padding: 1em;
}

.home-card1{
    transform: translateY(100px);
    opacity: 0;
}

.home-card2{
    transform: translateY(100px);
    opacity: 0;
}

@media screen and (max-width: 1047px){
    .hero .hero-wrapper .hero-top .hero-text h1{
        font-size: 3rem !important;
    }
}

@media screen and (max-width: 490px){
    .hero .hero-wrapper .hero-top .hero-img{
        width: 100% !important;
      }
}

@media screen and (max-width: 938px){
    .hero-top{
        flex-direction: column-reverse;
        text-align: center;
        justify-content: center !important;
        justify-self: flex-start;
        margin-top: auto;
        gap: 2em !important;
        height: 80vh !important;
    }

    .web-hero .web-hero-wrapper .hero-top .hero-text > :nth-child(3){
        margin: 0 auto;
    }

    .data-hero .data-hero-wrapper .hero-top .hero-text .web-p-bold{
        max-width: 100% !important;
    }

    .data-hero .data-hero-wrapper .hero-top .hero-text > :nth-child(3){
        max-width: 100% !important;
    }

    .it-hero .it-hero-wrapper .hero-top .hero-text > :nth-child(2){
        max-width: 100% !important;
    }

    .hero .hero-wrapper .hero-top .hero-text .main-p-hero{
        margin: 0 auto;
    }
    
    .see-more-hero{
        margin: 0 auto;
    }


  
    .hero .hero-wrapper .hero-top .hero-text h1 br{
      display: none;
    }

  
    .hero .hero-wrapper .hero-top .hero-text{
      max-width: 100%;
      gap: 0;
    }
  
    .hero .hero-wrapper .hero-top .hero-text p{
      max-width: 100%;
    }
  
   
}

@media screen and (max-width: 1684px){
    .doings-display{
        grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    }
}

@media screen and (max-width: 1351px){
    .doings-display{
        grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    }
}

@media screen and (max-width: 500px){
    .doings-display{
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }
}

@media screen and (max-width: 334px){
    .doings-display{
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
}

@media screen and (max-width: 698px){
    .hero .hero-wrapper .hero-top .hero-text h1{
        font-size: 2.5rem !important;
    }

    .hero .hero-wrapper .hero-top .hero-img{
        width: 300px !important;
    }
}

@media screen and (max-width: 490px){
    .hero .hero-wrapper .hero-top .hero-img{
        width: 100% !important;
      }
}

@media screen and (max-width: 481px){
    .hero .hero-wrapper .hero-top .hero-text h1{
        font-size: 2.5rem;
    }
}

@media screen and (max-width: 1127px){
    .doings-display .doings-main{
        margin-bottom: 80px;
    }
}