.about-hero{
    width: 90%;
    margin: 0 auto;
}

.aboutstar{
    position: absolute;
    left: -300px;
    top: -130px;
    width: 750px;
}

.aboutcircle{
    position: absolute;
    top: 15% !important;
    right: 30px;
}

.about-hero .about-hero-wrapper .about-hero-top{
    display: flex;
    width: 90%;
    justify-content: space-between;
    align-items: center;
    min-height: 84vh;
}

.about-hero-text{
    margin-right: 1em;
    clip-path: polygon(0 100%, 99% 100%, 100% 100%, 0% 100%);
    opacity: 0;
    transform: translateY(100px)
}

.about-hero .about-hero-wrapper .about-hero-top .about-hero-img{
    opacity: 0;
    transform: scale(.5);
}

.about-hero-text h1{
    font-size: 3rem;
    margin-bottom: .5em;
}

.we{
    color: var(--mainRed);
}

.about-hero-text p{
    font-size: 15px;
    max-width: 600px;
    line-height: 2;
    font-weight: normal;
    opacity: 60%;
}



.hero-text h1{
    margin-bottom: .2em;
    font-size: 3rem;
}

.about-bottom-hero-text{
    clip-path: polygon(0 100%, 99% 100%, 100% 100%, 0% 100%);
    opacity: 0;
    transform: translateY(100px);
}

.portfolio-btn-about{
    opacity: 0;
    transform: translateY(100px)
}

.hero-text p{
    opacity: 80%;
    font-size: 1.2rem;
    margin-bottom: .5em;
}

.hero-text .services-list{
    display: flex;
    flex-direction: column;
    gap: .5em;
}

.hero-text .services-list li{
    list-style-type: none;
    opacity: 60%;
}

.hero-bottom{
    margin-bottom: 70px;
    padding-top: 70px;
}

.mission{
    padding: 7em 0;
    align-items: center;
    justify-content: center;
    gap: 1em;
    margin-bottom: 100px;
    position: relative;
    display: flex;

}

/* .mission::after{
    content: url(/src/assets/goal.png);
    position: absolute;
    display: inline;
    margin-top: 70px;
    line-height: normal;
    vertical-align: middle;
    right: 0;
    top: 0;
} */

.mission .mission-heading{
    text-align: center;
    clip-path: polygon(0 100%, 99% 100%, 100% 100%, 0% 100%);
    opacity: 0;
    transform: translateY(100px);
    margin-left: 150px;
}

.mission .mission-img{
    transform: translateX(270px);
    opacity: 0;
}

.mission .mission-heading h1{
    font-size: 3rem;
    margin-bottom: .5em;
}

@media screen and (max-width: 490px){
    .star{
        width: 650px;
    }
}

@media screen and (max-width: 846px){
    .about-hero .about-hero-wrapper .about-hero-top{
        width: 100%;
    }
}

@media screen and (max-width: 846px){
    .about-hero .about-hero-wrapper .about-hero-top{
        flex-direction: column-reverse;
        text-align: left;
    }

    .about-hero .about-hero-wrapper .about-hero-top .about-hero-img img{
        width: 200px;
    }

    .about-hero{
        margin-top: 2em;
    }

    .about-hero-text h1{
        text-align: center;
    }

    .about-hero-text p{
        padding: 1em;
    }

    .about-hero-text{
        margin-right: 0em;
    }
}

@media screen and (max-width: 936px){
    .mission{
        flex-direction: column-reverse;
    }

    .mission .mission-img img{
        width: 100px;
    }

    .mission .mission-heading{
        margin-left: 0px;
    }
}

@media screen and (max-width: 470px){
    .aboutstar{
        width: 650px;
    }
}

@media screen and (max-width: 470px){
    .aboutstar{
        width: 650px;
    }
}

@media screen and (max-width: 366px){
    .aboutstar{
        width: 600px;
    }
    
}