.home-project{
    
    width: 90%;
    margin: 0 auto;
    margin-bottom: 70px;
}

.hero-bottom{
    margin-bottom: 70px;
}

.hero-text .project-text{
    color: var(--mainRed);
}

.home-project .home-project-wrapper .hero-bottom .projects-hero-text {
    clip-path: polygon(0 100%, 99% 100%, 100% 100%, 0% 100%);
    opacity: 0;
    transform: translateY(100px)
}

.home-project .home-project-wrapper .hero-bottom .projects-hero-btn{
    opacity: 0;
    transform: translateY(50px)
}

.home-project .hero-text h1{
    font-size: 2.5rem;
    margin-bottom: 23px;
    font-weight: bold;
}

.home-project .hero-text p{
    max-width: 400px;
    opacity: 0.7;
}

.main-projects{
    display: flex;
    justify-content: center;
    align-self: stretch;
}

.main-project-wrapper{
    /* display: grid; */
    width: 95%;
    /* grid-template-columns: repeat(auto-fill, minmax(30%, 1fr)); */
    /* justify-items: center; */
    gap: 3em;
    justify-content: center;
    height: 100%;
    display: flex;
    text-align: center;
}



.main-project-wrapper .project1 img{
    width: 100%;
    border-radius: 22px;
    margin-bottom: 10px;
}

/* .main-project-wrapper .project3{
    justify-self: stretch;
    align-self: stretch;
    justify-self: stretch;
} */

.main-project-wrapper .project1{
    width: 100%;
    transform: translateY(100px);
    opacity: 0;
}

.main-project-wrapper .project1 .project1-text{
    transform: translateY(30px);
    clip-path: polygon(0 100%, 99% 100%, 100% 100%, 0% 100%);
}

.main-project-wrapper .project1 .project1-text>:nth-child(2){
    color: var(--mainRed);
    margin-top: 10px;
}

@media screen and (max-width: 639px){
    .main-project-wrapper{
        flex-wrap: wrap;
        gap: 6em;
    }

    .main-project-wrapper .project1{
        width: 45%;
    }
}

@media screen and (max-width: 561px){
    .main-project-wrapper .project1{
        width: 70%;
    }
}