.data-hero{
    position: relative;
}

.datastar{
    position: absolute;
    left: -300px;
    top: -130px;
    width: 750px;
}

.datacircle{
    position: absolute;
    position: absolute;
    top: 1% !important;
    right: 30px;
}

.data-hero .data-hero-wrapper{
    width: 90%;
    margin: 0 auto;
}

.data-hero .data-hero-wrapper .hero-top{
    display: flex;
    justify-content: space-between;
    width: 90%;
    align-items: center;
    gap: 2em;
    min-height: 84vh;
    
}

.data-hero .data-hero-wrapper .hero-top .hero-text{
    display: flex;
    flex-direction: column;
}

.data-hero .data-hero-wrapper .hero-top .data-hero-text{
    clip-path: polygon(0 100%, 99% 100%, 100% 100%, 0% 100%);
    opacity: 0;
    transform: translateY(100px);
}

.data-hero .data-hero-wrapper .hero-top .data-hero-img{
    opacity: 0;
    transform: scale(.5);
    width: clamp(500px, 30vw, 570px);
}

.data-hero .data-hero-wrapper .hero-top .hero-text h1{
    font-size: clamp(3rem, 5vw, 6rem);
}

.data-hero .data-hero-wrapper .hero-top .hero-text>:nth-child(3){
    max-width: max(500px, 70%);
    opacity: 70%;
    font-size: clamp(15px, 1vw, 30px);
}

.data-hero .data-hero-wrapper .hero-top .hero-text>:nth-child(3){
    margin-bottom: 2em;
}

.data-hero .data-hero-wrapper .hero-top .hero-text>:nth-child(4){
    max-width: max(500px, 70%);
    opacity: 70%;
    font-size: clamp(15px, 1vw, 30px);
}

.data-hero .data-hero-wrapper .hero-top .hero-text .web-p-bold{
    font-size: 20px;
    opacity: 70%;
    max-width: 350px;
}

.data-hero .data-hero-wrapper .hero-bottom{
    margin-bottom: 20px;
    padding-top: 100px;
}

.data-hero .data-hero-wrapper .hero-bottom .data-service-text{
    clip-path: polygon(0 100%, 99% 100%, 100% 100%, 0% 100%);
    opacity: 0;
    transform: translateY(100px);
}

.data-hero .data-hero-wrapper .hero-bottom .data-service-portfolio{
    opacity: 0;
    transform: translateY(50px)
}


.data-hero .data-hero-wrapper .hero-bottom .hero-text p{
    max-width: 580px;
    opacity: 60%;
    font-size: 15px;
}

.data-hero .data-hero-wrapper .hero-bottom .hero-text h1{
    margin-bottom: .5em;
    font-size: clamp(2.2rem, 3.3vw, 3rem);
}

.data-doing-display{
    display: grid;
    gap: 5em;
    grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 4em 0;
    height: 100%;
    margin-bottom: 70px;
}

.data-doings-text{
    opacity: 0;
    transform: translateY(100px);
    clip-path: polygon(0 100%, 99% 100%, 100% 100%, 0% 100%);
}

.data-doings-img{
    opacity: 0;
    transform: translateY(100px);
}

@media screen and (max-width: 1038px){
    .data-hero .data-hero-wrapper .hero-top{
        width: 100%;
    }
}

@media screen and (max-width: 938px){
    .data-hero .data-hero-wrapper .hero-top img{
        width: 60% !important;
    }
}

@media screen and (max-width: 860px){
    .data-hero .data-hero-wrapper .hero-top img{
        width: 60% !important;
    }

    

    .hero-top{
        flex-direction: column-reverse;
        text-align: center;
        
    }

    .data-hero .data-hero-wrapper .hero-top .hero-text{
        gap: 1em;
    }

    .data-hero .data-hero-wrapper .hero-top .hero-text>:nth-child(3){
        max-width: 100%;
    }

    .data-hero .data-hero-wrapper .hero-top .hero-text .web-p-bold{
        max-width: 100%;
    }
}

@media screen and (max-width: 490px){
    .data-hero .data-hero-wrapper .hero-top img{
        width: 90% !important;
    }

    .data-hero .data-hero-wrapper .hero-top .hero-text h1{
        font-size: 2.5rem;
    }
}

@media screen and (max-width: 886px){
    .data-hero .data-hero-wrapper .hero-bottom .hero-text p{
        max-width: 500px;
    }
}

@media screen and (max-width: 782px){
    .data-hero .data-hero-wrapper .hero-bottom .hero-text p{
        max-width: 400px;
    }
}

@media screen and (max-width: 912px){
    .data-doing-display{
        align-items: flex-start;
    }
}

@media screen and (max-width: 556px){
    .data-doing-display{
        display: block;
    }

    
}
